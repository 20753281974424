import React, { useState } from "react";
import Navbar from "../Tool/Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Deploy() {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("Upload build");
  const [subDomainStatus, setSubDomainStatus] = useState([]);
  const [subdomain, setSubdomain] = useState("");
  const [checkTxt, setCheck] = useState("Check");
  const checkStatus = async () => {
    setCheck("Checking...");
    try {
      const response = await axios.post(
        `https://api.saeuietpu.in/check-subdomain/?domain=${subdomain}`
      );
      const data = response.data;
      console.log(data);
      setSubDomainStatus(data);
      setTimeout(() => {
        setCheck("Check");
      }, [2000]);
      setCheck("Checked");
    } catch (err) {
      setCheck("Try again!");
      console.log();
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    setUploadStatus("Uploading...")
    const formData = new FormData();
    formData.append("subdomain", subdomain);
    formData.append("file", file);

    try {
      const response = await axios.post(
        `https://api.saeuietpu.in/upload/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = response.data;
      console.log(response);
      navigate("/overview", { state: { resp: data } });
    } catch (error) {
      console.error("Upload error:", error);
      setUploadStatus("Error uploading file.");
    }
  };

  return (
    <>
      <Navbar />
      <div className="sidebar">
        <ul>
          <li
            onClick={() => {
              navigate("/overview");
            }}
          >
            Overview
          </li>
          <li>Deploy</li>
          <li>Environment</li>
          <li style={{ color: "#208B81" }}>New subdomain</li>
          <li>Contact Us</li>
        </ul>
      </div>
      <section>
        <div className="domain-check">
          <h3 style={{ textAlign: "center" }}>Check you desired subdomain</h3>
          <form action={checkStatus}>
            <label className="subdomain-box-label" htmlFor="subdomain">
              .saeuietpu.in
            </label>
            <input
              className="subdomain-box-input"
              type="text"
              value={subdomain}
              name="subdomain"
              onChange={(e) => setSubdomain(e.target.value)}
              id="subdomain-box"
              placeholder="dream it..."
            />
            <button className="publish-build-btn" type="submit">
              {checkTxt}
            </button>
          </form>
          {!subDomainStatus.check ? (
            <h4 style={{ color: "red", textAlign: "center" }}>
              {subDomainStatus.message}
            </h4>
          ) : (
            <h4 style={{ color: "#208B81", textAlign: "center" }}>
              {subDomainStatus.message}
            </h4>
          )}
        </div>
        <div className="upload-build">
          <h3 style={{ textAlign: "center" }}>Upload you build</h3>
          <form action={handleUpload}>
            <label className="subdomain-box-labeltwo" htmlFor="subdomain">
              .saeuietpu.in
            </label>
            <input
              disabled
              className="subdomain-box-input"
              type="text"
              value={!subDomainStatus?.check ? "" : subdomain}
              name="subdomain"
              id="subdomain-box"
              placeholder="Choose a valid subdomain"
            />
            <br />
            <input
              type="file"
              onChange={handleFileChange}
              name="file-upload"
              id="file-upload"
            />
            <br />
            <button className="publish-build-btn" type="submit">
              {uploadStatus}
            </button>
          </form>
        </div>
      </section>
    </>
  );
}
