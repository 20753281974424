import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Manage from "./Components/Manage";
import EnvPage from "./Components/EnvPage";
import Help from "./Components/Help";
import Deploy from "./Components/Deploy";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/overview" element={<Manage />} />
          <Route path="/environment-variables" element={<EnvPage />} />
          <Route path="/ask-help" element={<Help />} />
          <Route path="/" element={<Deploy />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
