import React from "react";
import Navbar from "../Tool/Navbar";
import Sidebar from "../Tool/Sidebar";
import { useNavigate, useLocation } from "react-router-dom";

export default function Manage() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.resp;
  console.log(data)
  return (
    <>
      <Navbar />
      <div className="sidebar">
        <ul>
          <li style={{ color: "#208B81" }}>Overview</li>
          <li>Deploy</li>
          <li>Environment</li>
          <li
            onClick={() => {
              navigate("/");
            }}
          >
            New subdomain
          </li>
          <li>Contact Us</li>
        </ul>
      </div>
      <section>
        <div className="domain-conf">
          <div>
            <p className="deploy-name">{data?.subDomain}</p>
            <a
              href={data?.subdomain}
              target="_blank"
              className="subdomain-name"
            >
              {data?.subdomain}
            </a>
            <p className="publish-type">{data?.deployType}</p>
            <p className="publish-time">{data?.timestamp}</p>
            <button className="publish-build-btn">Publish another build</button>
          </div>
          <div className="preview-win">
            <iframe src={data?.subdomain} loading="lazy"></iframe>
          </div>
        </div>
        <div className="domain-info">
          <h4>Sub-Domain Information</h4>
          <table>
            <tbody>
              <tr>
                <td>ID</td>
                <td>{data?.id}</td>
              </tr>
              <tr>
                <td>username</td>
                <td>{data?.username}</td>
              </tr>
              <tr>
                <td>Sub-Domain ID</td>
                <td>{data?.subDomain}</td>
              </tr>
              <tr>
                <td>sub-Domain</td>
                <td>
                  <a
                    href={data?.subdomain}
                    target="_blank"
                    className="subdomain-name"
                  >
                    {data?.subdomain}
                  </a>
                </td>
              </tr>
              <tr>
                <td>Deploy Type</td>
                <td>{data?.deployType}</td>
              </tr>
              <tr>
                <td>Timestamp</td>
                <td>{data?.timestamp}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="prev-build-deploy">
          <h3 style={{ marginBottom: "10px" }}>Build history</h3>
          <hr className="hori-line" />
          <table>
            <tbody>
              <tr className="prev-build">
                <td>
                  <p style={{ fontSize: "17px", fontWeight: 600 }}>{data?.id}</p>
                  <p style={{ fontSize: "13px", fontWeight: 400 }}>{data?.timestamp}</p>
                </td>
                <td>
                  <button className="publish-build-btn">Active</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
