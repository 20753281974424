import React from "react";

export default function Navbar() {
  return (
    <header>
      <div className="navDivider">Webify</div>
      <div className="navDivider">/</div>
      <div style={{ color: "#054942" }} className="navDivider">
        User1
      </div>
    </header>
  );
}
